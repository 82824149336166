import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Footer() {
    return (
        <>
        <footer>
            <Container>
                <Row>
                    <Col md className="footerTile">
                        {/* <SocialLinks />
                        <p>Thanks for stopping by!</p> */}
                    </Col>
                </Row>
            </Container>
        </footer>
        </>
    );
};

export default Footer;