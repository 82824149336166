import './App.css';
import './styles/baseball.css';
import './styles/socialLinks.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import Footer from './components/Footer';
import Header from './components/Header';

import Home from './pages/Home';
import Baseball from './pages/Baseball'

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <>
    <div className="pageContainer">
      <Router>
        <Header />
            <Routes>
              <Route path="/" element={<Home />}></Route>
              <Route path="/baseball" element={<Baseball />}></Route>
            </Routes>
          <Footer />
      </Router>
    </div>
    </>
  );
}

export default App;
