import React, { useEffect } from 'react';

import { Link } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Home() {

    useEffect(() => {
        document.title = "Alexander McLeod | Computer Science";
    });

    return (
        <>
            <Container className="mainContainer">
                <section id="about">
                    <Row>
                        <Col md={2}>
                            <div class="sectionTitle">
                                <p>About Me</p>
                            </div>
                        </Col>
                        <Col  lg={10} xl={8}>
                            <div class="sectionContent">
                                <p>I am a computer science student at Oregon State University. I will be graduating in June 2024 with a Bachelor of Science in Computer Science. In 2015, I graduated from Montana State University with a BS in Business Management. </p>
                                <p>My goal is to become a full-time software engineer.</p>
                            </div>
                        </Col>
                    </Row>
                </section>

                <section id="projects">
                    <Row>
                        <Col md={2}>
                            <div class="sectionTitle">
                                <p>Projects</p>
                            </div>
                        </Col>
                        <Col  lg={10} xl={8}>
                            <div className="sectionContent">
                                <p className="projectTitle"><a href="https://alexmcleod.me">alexmcleod.me</a></p>
                                <p className="projectDescription">A website built with Express, React, Node, and Bootstrap. Deployed with Google Cloud.</p>

                                <p className="projectTitle"><a href="https://skymo-usa.com">skymo-usa.com</a></p>
                                <p className="projectDescription">A website built with Express, Node, Handlebars, and Bootstrap. Deployed with Google Cloud.</p>

                                <p className="projectTitle"><a href="https://github.com/alexjmcleod/python-tic-tac-toe">Python Command Line Tic-Tac-Toe</a></p>
                                <p className="projectDescription">A CLI app written in Python.</p>

                                <p className="projectTitle"><Link to="/baseball">Baseball</Link></p>
                                <p className="projectDescription">Part of alexmcleod.me, this page queries the MLB REST API to get schedule data.</p>

                                <p className="projectTitle"><a href="https://youtu.be/ETMVFeS3Ytw">Python GUI Calculator</a></p>
                                <p className="projectDescription">A calculator app with a GUI. Written in Python using the Tkinter package.</p>
                            </div>
                        </Col>
                    </Row>
                </section>

                <section id="coursework">
                    <Row>
                        <Col md={2}>
                            <div class="sectionTitle">
                                <p>Coursework</p>
                            </div>
                        </Col>
                        <Col lg={10} xl={8}>
                            <div className="sectionContent">
                                <p>Algorithms</p>
                                <p>Assembly Language</p>
                                <p>Databases</p>
                                <p>Data Structures</p>
                                <p>Operating Systems</p>
                                <p>Parallel Programming</p>
                                <p>Software Engineering</p>
                                <p>Web Development</p>
                                <p>Harvard's CS50x</p>
                                <p>Harvard's CS50P</p>
                            </div>
                        </Col>
                    </Row>
                </section>

                <section id="skills">
                    <Row>
                        <Col md={2}>
                            <div class="sectionTitle">
                                <p>Skills</p>
                            </div>
                        </Col>
                        <Col lg={10} xl={8}>
                            <div className="sectionContent">
                                <p className="skillsGroupTitle">Languages and Frameworks</p>
                                <p className="skillsDescription">Java, Python, JavaScript, HTML, CSS, Node.js, Express.js, React</p>

                                <p className="skillsGroupTitle">Other</p>
                                <p className="skillsDescription">Strong Background in Data Structures, AWS Certified Cloud Practitioner</p>
                            </div>
                        </Col>
                    </Row>
                </section>
            </Container>

        </>
    );
};

export default Home;