import React from 'react';

function SocialLinks() {
    return (
        <>
            <div className="socialLinks">
                <a target="_blank" rel="noreferrer"  href="https://www.linkedin.com/in/alexjmcleod/">
                    <i className="fa-brands fa-linkedin"></i>
                </a>
                <a target="_blank" rel="noreferrer"  href="https://github.com/alexjmcleod">
                    <i className="fa-brands fa-square-github"></i>
                </a>
            </div>
        </>
    );
};

export default SocialLinks;