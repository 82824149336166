import React from 'react';
import { Link } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SocialLinks from './SocialLinks';


function Header() {
    return (
        <>
            <header>
                <Container className="mainContainer">
                    <Row>
                        <Col md={2}>
                            {/* Spacer column */}
                        </Col>
                        <Col  lg={10} xl={8}>
                            <h1><Link to="/">Alexander McLeod</Link></h1>
                            <h6>Computer science student seeking full-time employment as a software developer.</h6>

                            <SocialLinks />
                        </Col>
                        {/* <Col md>
                            <Navigation />
                        </Col> */}
                    </Row>
                </Container>
                
            </header>
        </>
    );
};

export default Header;