import React, { useState, useEffect } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


function Baseball() {

    const [nextGame, setNextGame ] = useState({});

    const loadNextGame = async () => {
        let response = await fetch("/baseball-data");
        response = await response.json();
        setNextGame(response);
    }

    // Call loadNextGame on initial page load.
    useEffect(() => {
        loadNextGame();
    }, []);

    useEffect(() => {
        document.title = "Mariners Next Game";
    }, []);


    return (
        <>

                <Row>
                    <Col md={2}>
                        {/* Empty spacer */}
                    </Col>
                    <Col>
                        <div>
                            <h1>Baseball</h1>
                            <p>Some baseball stuff for quick reference. Grabbed via the MLB API.</p>
                        </div>
                    </Col>
                </Row>
                

                
            <section id="baseball">
                <Row>
                    <Col md={2}>
                        {/* Empty spacer */}
                    </Col>
                    <Col md={12} xl={8}>
                    

                <Container fluid>                    
                    <Row>
                        <Col>
                            <h1>Next Mariners Game</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="header">
                            <div>{new Date(nextGame.gameDate).toLocaleDateString("en-us", {weekday: "long", month: "long", year: "numeric", day: "numeric"})}</div>
                            <div>{new Date(nextGame.gameDate).toLocaleTimeString("en-us",{hour: "numeric", minute: "numeric"})}</div>
                            <div>{nextGame.springTraining === true ? "Spring Training" : ""}</div>
                            <div>{nextGame.location}</div>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col sm={4} lg={5}>
                        <div className="teamName">
                            {nextGame.awayTeam}
                        </div>
                        <div className="teamRecord">
                            {nextGame.awayWins}-{nextGame.awayLosses}
                        </div>

                        </Col>
                        <Col sm={2}>
                        <div className="teamName at">
                            &#64;
                        </div>
                        </Col>
                        <Col sm={4} lg={5}>
                        <div className="teamName">
                            {nextGame.homeTeam}
                            </div>
                        <div className="teamRecord">
                            {nextGame.homeWins}-{nextGame.homeLosses}
                        </div>
                        </Col>
                    </Row>
                </Container>
                </Col>
                </Row>
            </section>
        </>
    );
};

export default Baseball;